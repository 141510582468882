:root {
  --main-blue: #2e49be;
  --main-white: #ffffff;
  --light-gray: #f8f8f8;
  --dark-gray: #f0f0f0;
  --darkest-gray: #262d3e;
  --main-black: #262d3e;
  --form-control: #495057;
  --main-blue-hover: #2934af;
  --main-font: 'Montserrat';
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--main-font);
  overflow-x: hidden;
  background: url('../images/Background.svg');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 100vw;
}

.text-main {
  color: var(--main-blue) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

a {
  text-decoration: none !important;
}

.nav-link {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 33px;
  color: var(--light-gray) !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.nav-link:hover {
  color: #cacaca !important;
  transition: 0.2s all ease-in-out;
}

.active .nav-link {
  font-weight: 700;
}

.sign-up-btn {
  text-align: center;
  font-family: var(--main-font);
  font-style: normal;
  font-weight: bold;
  /* font-size: 1.25rem; */
  color: var(--main-blue) !important;
  background: var(--white);
  border-radius: 25px;
  padding: 5px 30px;
  min-width: 130px;
}

.sign-up-btn:hover {
  color: var(--main-blue) !important;
  transition: 0.2s all ease-in-out;
  background: #ebe9e9;
}

.hero-title {
  text-align: center;
  font-family: var(--main-font);
  font-style: normal;
  font-weight: bold;
  /* font-size: 3.75rem; */
  color: var(--main-white);
}

.hero-t {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  /* font-size: 1.5rem; */
  color: var(--light-gray);
  margin-top: 20px;
}

.hero-section {
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-hero::after {
  content: url('../images/icon-down.svg');
  position: relative;
  top: 250px;
}

.serach-section {
  background: var(--main-white);
  padding: 8px 24px;
  border-radius: 100px;
  margin-top: 60px;
}

.search-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-input {
  outline: none;
  border: none;
  background: transparent;
}

.search-input:focus {
  outline: none !important;
  border: none;
}

.first-part .fa-search,
.second-part .fa-map-marker-alt {
  color: #d5d5d5;
  /* font-size: 1.25rem; */
  padding-right: 4px;
}

.first-part,
.second-part {
  display: flex;
  align-items: center;
}

.first-part {
  width: 60%;
}

.second-part {
  width: 30%;
}

.border-part {
  border-right: 1px solid #d5d5d5;
  width: 1px;
  height: 50px;
  margin-right: 10px;
}

.search-btn {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: var(--main-blue);
}

.search-btn:hover {
  background: #2934af;
}

.search-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -15px;
}

.search-btn i {
  font-size: 1.5rem;
  color: var(--main-white);
}

.search-a:hover {
  text-decoration: none;
}

.skill-section {
  margin-top: 300px;
}

.skill-m-title {
  /* font-size: 1.5rem; */
  color: var(--main-blue);
  text-transform: uppercase;
  font-weight: bold;
  font-family: var(--main-font);
  margin-bottom: 20px;
}

.card-title {
  /* font-size: 1.25rem; */
  font-family: var(--main-font);
  color: var(--main-white);
  font-weight: bold;
  text-shadow: 0px 3px 20px #000000;
  text-transform: uppercase;
  margin-bottom: 0 !important;
}

.card-img-overlay.layer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3666d32b;
  border-radius: 10px !important;
}

.card-img-overlay.layer:hover {
  background: #04277741;
  transition: 0.2s all ease-in-out;
}

.card {
  border-radius: 10px !important;
  border: none !important;
}

.skill-section .card-img-top {
  border-radius: 10px;
}

.skill-description {
  /* font-size: 1rem; */
  color: var(--main-white);
  font-weight: medium;
  line-height: 19px;
  margin-bottom: 5px !important;
}

.portfolio-section {
  margin-top: 150px;
}

.portfolio-section .card,
.portfolio-section img,
.card-img-overlay.portfolio {
  border-radius: 10px;
}

.card-img-overlay.portfolio {
  display: flex;
  align-items: flex-end;
}

.card-img-overlay.portfolio:hover {
  background: #04277710;
  transition: 0.2s all ease-in-out;
}

.star-rate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rate {
  background: var(--main-blue);
  border-radius: 50px;
  color: var(--main-white);
  letter-spacing: -0.5px;
  font-family: var(--main-font);
  font-size: 0.825rem;
  font-weight: 600;
  padding: 5px 8px;
}

.contact-us {
  background: url('../images/bleu-bg.svg');
  padding: 80px 0px;
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: 80%;
  margin-top: 150px;
}

.freelancer-text {
  font-weight: bolder;
  color: var(--main-white);
  font-family: var(--main-font);
}

.contact-text {
  font-weight: bold;
  color: var(--main-white);
  font-family: var(--main-font);
}

.contact-us-now p {
  color: var(--main-white);
  font-family: var(--main-font);
}

.price-font {
  font-size: 3.75rem;
  line-height: 50px;
  font-weight: bolder;
  color: var(--main-blue);
  font-family: var(--main-font);
}

.small-font {
  font-size: 1.25rem;
  font-family: var(--main-font);
}

.contact-us-btn {
  background: var(--main-blue);
  padding: 12px 24px;
  color: var(--main-white);
  border-radius: 50px;
  font-size: 1.125rem;
  font-family: var(--main-font);
  font-weight: bolder;
  box-shadow: 0px 3px 15px 7px #0000001a;
  display: inline-block;
}

.contact-us-btn:disabled {
  cursor: not-allowed;
  opacity: 0.75;
}

.contact-us-btn:hover {
  background: #2934af;
}

.footer-bg {
  background: var(--darkest-gray);
}

.footer-inner {
  padding-top: 56px;
  padding-bottom: 44px;
}

.contact-us-footer-btn {
  background: var(--main-white);
  padding: 10px 24px;
  color: var(--main-blue);
  border-radius: 50px;
  font-size: 1.25rem;
  font-family: var(--main-font);
  font-weight: bolder;
  display: inline-block;
}

.contact-us-footer-btn:hover {
  background: #ebe9e9;
}

footer h4 {
  color: var(--main-white);
  font-family: var(--main-font);
  font-weight: 600;
  margin-bottom: 20px;
}

footer p a {
  color: #747e94;
  font-size: 1.125rem;
  font-weight: normal;
}

footer p a:hover {
  color: #9ba1af;
  text-decoration: none;
}

.social-icon i {
  font-size: 2.5rem;
  margin: 0px 8px;
  color: #747e94;
}

.social-icon i:hover {
  color: #9ba1af;
}

footer .row>*+* {
  display: flex;
  justify-content: center;
}

.navbar-toggler {
  border: none !important;
  outline: none !important;
  margin-right: -20px;
}

.card-columns {
  column-gap: 15px !important;
}

/* signup css */
body.white-bg {
  background: none;
}

.white-bg .nav-link {
  color: var(--main-black) !important;
}

.white-bg .nav-link:hover {
  color: #666666 !important;
}

.white-bg .sign-up-btn {
  background: var(--main-blue);
  color: var(--main-white) !important;
}

.white-bg .sign-up-btn:hover {
  background: var(--main-blue-hover);
  color: var(--main-white) !important;
}

.signup-section.width {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.signup-section {
  margin-top: 32px;
}

.signup-section a {
  color: var(--main-blue);
}

.signup-section a:hover {
  color: var(--main-blue-hover);
}

h1.main-title {
  color: var(--main-black);
  font-size: 2.75rem !important;
  font-family: var(--main-font);
  font-weight: bolder;
}

.form-section input {
  border-radius: 50px;
  padding: 30px;
  border: 1px solid #132057;
  font-family: var(--main-font);
}

.form-text,
.forgot-password {
  font-family: var(--main-font);
}

.btn-next {
  background: var(--main-blue) !important;
  color: var(--main-white) !important;
  padding: 16px 16px !important;
  display: block !important;
  font-size: 1.25rem !important;
  font-family: var(--main-font);
  font-weight: bold !important;
  border-radius: 50px !important;
  text-decoration: none !important;
  width: 100%;
}

.btn-next:hover {
  background: var(--main-blue-hover) !important;
}

.ac-text {
  font-family: var(--main-font);
}

.white-bg .header-nav-bg {
  background: none;
}

.login-section {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* home page */
.home-hero-section {
  display: flex;
  justify-content: space-between;
}

.home-hero-section .hero-title {
  margin-top: 250px;
}

.home-img-part {
  margin-top: 120px;
  max-width: 50%;
}

.arrow-img {
  margin-top: 145px !important;
  position: absolute;
  left: calc(50% - 15.8px);
}

.home-hero-txt {
  font-size: 1.25rem;
}

h4.sub-head {
  font-family: var(--main-font);
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 500px;
  text-transform: uppercase;
}

h4.sub-head::after {
  content: url('../images/right-arrow.svg');
  margin-left: 12px;
}

.sub-text {
  color: #a3a3a3;
  font-family: var(--main-font);
  font-size: 1.125rem;
}

.proxy-box {
  display: flex;
  background: var(--main-white);
  box-shadow: 0px 3px 15px 7px #00000010;
  border-radius: 10px;
}

.proxy-box img {
  min-height: 165px;
  max-width: 225px;
}

.proxy-buttons {
  padding: 8px 24px;
  background: var(--main-white);
  box-shadow: 0px 3px 15px 7px #00000010;
  border-radius: 74px;
  width: 40%;
  justify-content: space-between;
  margin: 20px 24px;
  display: inline-block;
}

.proxy-text {
  padding: 10px 15px;
  font-family: var(--main-font);
}

.proxy-text .blue {
  font-size: 1rem;
  color: var(--main-blue);
}

.proxy-title {
  font-family: var(--main-font);
}

.p-btn {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
}

.proxy-buttons:hover {
  transform: scale(1.02);
  transition: 0.2s all ease-in-out;
}

.proxy-buttons svg {
  width: 0.75rem;
}

.proxy-btn-part a:hover {
  text-decoration: none;
}

.proxy-btn-part {
  text-align: center;
}

.p-btn span {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--main-black);
}

.mt100 {
  margin-top: 100px;
}

.mt150 {
  margin-top: 150px;
}

.card .card-img-overlay,
.card>img {
  border-radius: 10px;
}

/* .find-freelancer img,
.find-freelancer .card-img-overlay {
  border-radius: 10px;
} */

.grey-badge .grey-bg {
  background: #efefef;
  border-radius: 5px;
  color: #a0a0a0;
  font-family: var(--main-font);
  font-weight: 600;
  padding: 3px 5px;
}

.grey-badge .date {
  color: #a0a0a0;
  float: right;
  font-family: var(--main-font);
  font-weight: 500;
}

.news-title {
  color: var(--main-black);
  font-family: var(--main-font);
  font-weight: bold;
  margin-top: 10px;
  text-shadow: none;
}

.news-text {
  color: var(--main-black);
  font-family: var(--main-font);
  font-weight: 400;
  margin-top: 12px;
}

.more-btn {
  background: var(--main-blue) !important;
  color: var(--main-white) !important;
  font-size: 1rem;
}

.more-btn:disabled {
  background: var(--main-blue) !important;
  cursor: not-allowed;
  opacity: 0.75;
}

.more-btn:hover {
  background: var(--main-blue-hover) !important;
}

.more-btn.bg-secondary {
  background-color: var(--form-control) !important;
  color: var(--main-white) !important;
}

.news-box-part:hover a {
  text-decoration: none;
}

/* wiki section */

.blog-section .nav-link {
  color: var(--main-black) !important;
}

.blog-section .nav-link:hover {
  color: gray !important;
}

.blog-section .nav-link.active {
  color: var(--main-black) !important;
  background-color: var(--light-gray) !important;
  border-radius: 25px;
}

.top-proxy-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.bots-part {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.box-icon {
  border-radius: 10px;
  box-shadow: 0px 3px 15px 7px #00000010;
  padding: 24px 16px;
}

.logo-grey-box {
  background-color: var(--dark-gray);
  border-radius: 10px;
  padding: 24px 16px;
  text-align: center;
}

.logo-grey-box-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  justify-content: center;
}

.message-part {
  font-family: var(--main-font);
}

.live-box {
  background-color: var(--light-gray);
  height: 150px;
  width: 100%;
}

.nav-item.dropdown.show .grey-bg-dropdown {
  background-color: var(--light-gray);
  border-radius: 50px;
}

.grey-menu {
  background-color: var(--light-gray) !important;
  border: none !important;
  border-radius: 20px !important;
  overflow: hidden;
}

.dropdown-menu.grey-menu.show {
  display: flex;
}

/* hire-search */
.gry-badges {
  background-color: var(--dark-gray);
  border-radius: 10px;
  display: inline-block;
  font-family: var(--main-font);
  font-weight: 500;
  padding: 8px 10px;
  margin: 5px;
}

.close-icon::after {
  content: url('../images/close-icon.svg');
  margin-left: 5px;
}

.freelancer-found {
  color: var(--main-black);
  display: flex;
  font-size: 2.5rem;
  font-family: var(--main-font);
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.freelancer-count {
  color: var(--main-blue);
  font-size: 5rem;
  font-family: var(--main-font);
  font-weight: bold;
  opacity: 0.5;
  padding-right: 15px;
}

.filter-title {
  margin-top: 12px;
}

.filter-text {
  background-color: var(--dark-gray);
  display: inline-block;
  cursor: pointer;
  font-family: var(--main-font);
  /* font-size: 1rem; */
  font-weight: 600;
  margin-bottom: 15px;
  padding: 4px 8px;
  border-radius: 10px;
}

.mt-50 {
  margin-top: 50px;
}

.card-columns.grid-result {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 50px;
}

/* hire-profile-view */
.profile-person {
  display: flex;
  align-items: center;
}

.propos-text {
  overflow-y: scroll;
  max-height: 250px;
}

.social-media-icon-profile img {
  width: 35px;
  height: 40px;
}

img.img-fluid.behance-logo {
  width: 50px;
}

.profile-rate {
  border: 3px solid #fabb29;
  border-radius: 25px;
  color: #fabb29;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 8px 24px;
  text-transform: uppercase;
}

.profile-proposer-btn a {
  background: var(--main-blue);
  border-radius: 50px;
  box-shadow: 0px 0px 10px rgb(46 73 190 / 42%);
  color: var(--main-white) !important;
  font-family: var(--main-font);
  font-size: 0.875rem;
  font-weight: 600;
  padding: 15px 25px;
  text-align: center;
  text-transform: uppercase;
}

.profile-proposer-btn a:hover {
  background: var(--main-blue-hover);
}

.language-section {
  line-height: 2;
}

.language-section h5,
.profile-social-media h5,
.skill-sec h5 {
  color: var(--main-black);
  font-family: var(--main-font);
  font-weight: bold;
  margin-top: 10px;
  text-transform: uppercase;
  text-shadow: none;
}

.language-section div {
  line-height: 2;
  font-family: var(--main-font);
}

.social-media-icon-profile {
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}

.skill-sec ul li {
  box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
  border-radius: 50px;
  float: left;
  font-family: var(--main-font);
  font-weight: 500;
  /* width: 45%; */
  margin: 5px;
  padding: 10px 24px;
}

.right-skill ul li {
  box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
  border-radius: 50px;
  float: left;
  font-family: var(--main-font);
  /* font-size: 0.875rem; */
  font-weight: 500;
  width: 90%;
  padding: 12px 12px;
  margin: 5px;
}

.experience-section h5,
.experience-box h5,
.portfolio-section-project h5 {
  color: var(--main-black);
  font-family: var(--main-font);
  font-weight: bold;
  margin-top: 10px;
  text-transform: uppercase;
  text-shadow: none;
}

.exp-designation,
.exp-badges h6 {
  color: var(--main-black);
  font-family: var(--main-font);
  font-weight: bold;
  margin-top: 10px;
  text-transform: uppercase;
  text-shadow: none;
}

.experience-box {
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 20px 30px;
}

.btn-outline-primary {
  border: 1.5px solid var(--main-blue) !important;
  border-radius: 20px !important;
  color: var(--main-blue) !important;
  font-family: var(--main-font);
  margin: 5px;
}

.btn-outline-primary:hover {
  background-color: unset !important;
  color: none;
  cursor: unset !important;
}

.exp-date {
  color: #838383;
}

.project-box {
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  border-radius: 15px;
  margin-bottom: 24px;
  max-width: 300px;
  position: relative;
}

.project-box .btn-delete {
  position: absolute;
  z-index: 1;
  right: 4px;
  top: 4px;
  color: red;
  font-size: 0.875rem;
  font-weight: 600;
  box-shadow: none !important;
  outline: none !important;
}

.project-image img {
  border-radius: 15px;
}

.project-title h6 {
  font-size: 1.125rem;
  font-family: var(--main-font);
  padding: 25px 17px;
  text-align: center;
}

.propos-text {
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}

.profile-name {
  color: var(--main-black);
  font-family: var(--main-font);
  font-weight: bold;
  margin-top: 10px;
  text-shadow: none;
  text-transform: uppercase;
}

.profile-designation {
  font-size: 1.25rem;
}

.left-skill h6,
.right-skill h6 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 10px;
}

/* contact-us page */
.three-tab-section {
  margin-right: 48px;
}

.three-tab-section li {
  border: 1px solid #bab7ec;
  border-radius: 10px;
  display: block;
  position: relative;
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
}

.three-tab-section li::after {
  content: url('../images/btn-arrow.svg');
  position: absolute;
  right: 16px;
  top: 16px;
}

.three-tab-section li a {
  padding: 15px 15px;
}

.three-tab-section li a.active {
  background-color: #bab7ec !important;
  color: var(--main-white);
}

.three-tab-section li a.active:hover {
  color: var(--main-white) !important;
}

.contact-us-form {
  box-shadow: 0px 3px 15px 7px #00000010;
  background-color: var(--main-white);
  border-radius: 20px;
  width: 30vw;
  padding: 30px;
}

.contact-head {
  color: #fabb29;
  font-family: var(--main-font);
  font-weight: bold;
  text-transform: uppercase;
}

.contact-us-form input {
  background-color: #f6f6f6;
  border-radius: 10px;
  border: none;
  padding: 8px 16px !important;
  border: none !important;
}

.contact-us-form label {
  font-family: var(--main-font);
  font-weight: bold;
  padding-left: 16px;
  text-transform: uppercase;
}

.contact-us-form textarea {
  background-color: #f6f6f6;
  border-radius: 10px;
  border: none;
  padding: 24px;
}

.btn-contact-yellow {
  background-color: #fabb29 !important;
  border-radius: 50px !important;
  color: var(--main-white) !important;
  display: block !important;
  font-weight: bold !important;
  margin: 15px auto;
  padding: 12px 70px !important;
  outline: none !important;
}

body.c-form-page {
  background-position-y: -30vh !important;
}

.form-head-text {
  font-family: var(--main-font);
  margin-top: 5px;
}

/* blog-section */
.blog-main-title {
  font-family: var(--main-font);
  /* font-size: 3.25rem; */
  font-weight: bold;
}

.blog-sub-title {
  font-family: var(--main-font);
}

.blog-details-section img {
  border-radius: 5px !important;
  box-shadow: 0px 1px 15px 7px #00000009;
}

/* blog-artical */
.blog-inner-title {
  font-family: var(--main-font);
  font-weight: bold;
  margin-top: 56px;
}

.date-blue {
  /* font-size: 1.125rem; */
  font-weight: 500;
}

.date-blue span {
  color: var(--main-blue);
  font-family: var(--main-font);
  /* font-size: 1.125rem; */
  font-weight: 500;
}

/* .artical-text p,
.second-artical-text p,
.blog-text p {
  color: #9b9b9b;
  font-family: var(--main-font);
  font-weight: 500;
} */

.artical-inner h5 {
  font-family: var(--main-font);
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 30px;
}

.last-artical-head {
  font-family: var(--main-font);
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 30px;
  text-transform: uppercase;
}

.q-section {
  position: relative;
  width: 75%;
  margin: 0px auto;
}

.q-section h5 {
  color: var(--main-blue);
  font-family: var(--main-font);
  font-weight: bold;
  margin: 50px auto;
}

.q-section h5::before {
  content: url('../images/q-left.svg');
  position: absolute;
  left: -80px;
}

.q-section h5::after {
  content: url('../images/q-right.svg');
  position: absolute;
  right: -80px;
}

/* dashboard-freelance */
#wrapper {
  overflow-x: hidden;
  background-color: var(--dark-gray);
  position: relative;
}

.page-content-dashboard {
  background-color: var(--main-white);
  margin-top: 10px;
  border-radius: 15px;
  padding: 25px;
  height: auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.page-content-dashboard.custom-height {
  height: 70vh !important;
}

#sidebar-wrapper {
  width: 320px;
  float: left;
  margin-left: 0px;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  padding: 20px;
  position: relative;
}

.hire-search #sidebar-wrapper {
  background: var(--main-white);
  border-top: 1px solid var(--dark-gray);
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow-y: scroll;
  z-index: 1;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

.hire-search #sidebar-wrapper .sidebar-heading {
  padding: 0.75rem 1rem;
  font-size: 1rem;
}

#sidebar-wrapper .list-group {
  width: 280px;
}

#wrapper.toggled .search-result {
  padding-left: 250px;
}

#page-content-wrapper {
  min-width: 100vw;
  min-height: calc(100vh - 88px);
  padding: 15px 8px;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: -360px;
}

.list-group-item {
  background-color: transparent !important;
  border: none !important;
  color: var(--main-black);
  margin-bottom: 20px;
}

.list-group-item span {
  padding-left: 10px;
}

.list-group-item .logout {
  color: #b41717;
}

.list-group-item.active {
  background-color: var(--main-blue) !important;
  border-radius: 10px !important;
  box-shadow: 1px 1px 5px -2px var(--main-blue);
  color: var(--main-white);
}

.list-group-item.active svg {
  fill: var(--main-white);
}

.list-group-item.active:hover {
  background-color: var(--main-blue-hover) !important;
  transition: 0.2s all ease-in-out;
}

.list-group-item:hover {
  background-color: rgb(226, 226, 226) !important;
  border-radius: 10px !important;
  transition: 0.2s all ease-in-out;
}

.dashboard-subscribe-content h5 {
  font-size: 20px;
  font-family: var(--main-font);
  font-weight: 700;
  margin-bottom: 15px;
}

/* subscribe-vide */
.subscribe-vide {
  justify-content: unset;
  align-items: unset;
  height: auto;
}

.react-tagsinput {
  border: 0px;
}

.react-tagsinput .custom-tagsinput-tag {
  background: transparent !important;
  border: 1.5px solid var(--main-blue);
  border-radius: 50px;
  color: var(--main-blue) !important;
  font-family: var(--main-font);
  font-size: 1rem;
  font-weight: 500;
  padding: 4px 12px;
}

.img-upload-btn,
.active-btn {
  border: 2px solid var(--main-blue) !important;
  border-radius: 15px;
  color: var(--main-blue) !important;
  display: inline-block;
  font-family: var(--main-font);
  font-size: 18px;
  font-weight: bold;
  padding: 6px 15px;
  outline: none !important;
}

.active-btn.deactivate {
  border: 2px solid #FF0000 !important;
  color: #FF0000 !important;
}

.img-upload-btn:hover,
.active-btn:hover {
  background: var(--light-gray) !important;
  border: 2px solid var(--main-blue-hover) !important;
  color: var(--main-blue-hover) !important;
}

.price-by-day h6 {
  font-family: var(--main-font);
  font-weight: bold !important;
  margin-top: 20px;
}

.price-text-add {
  border: 2px solid #fabb29 !important;
  border-radius: 50px;
  color: #fabb29 !important;
  font-size: 1.125rem;
  font-weight: 700;
  max-width: 75px;
  padding: 5px;
  text-align: center;
  outline: none !important;
  box-shadow: none !important;
}

.price-text-add:focus {
  outline: none;
}

.price-symbol {
  width: 25px;
  height: 25px;
  border: 1px solid #fabb29;
  color: #fabb29;
  border-radius: 100px;
  text-align: center;
  font-weight: bold;
  display: inline-table;
}

.price-symbol.active {
  width: 25px;
  height: 25px;
  background-color: #fabb29;
  color: var(--main-white);
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
}

.price-part {
  display: flex;
  align-items: center;
}

.dash-head h2 {
  font-family: var(--main-font);
  font-size: 25px;
  font-weight: 700;
}

.dashboard-form input {
  background: var(--main-white);
  box-shadow: 0px 2px 10px 5px #75757510;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-family: var(--main-font);
}

.dashboard-form textarea {
  background: var(--main-white);
  box-shadow: 0px 2px 10px 5px #75757510;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-family: var(--main-font);
  padding: 15px;
}

.dash-lanuguage select,
.react-select .profile-react-select__control,
.react-select .hire-search-react-select__control {
  background: var(--main-white);
  box-shadow: 0px 2px 10px 5px #75757510;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-family: var(--main-font);
  padding: 0px 15px;
}

.react-select .hire-react-select__control,
.react-select .hire-search-react-select__control {
  background: transparent !important;
  box-shadow: none !important;
  border: none;
  font-size: 16px;
  font-family: var(--main-font);
  padding: 0px 0px;
}

.react-select .hire-search-react-select__value-container--is-multi {
  padding: 2px 4px;
}

.react-select .hire-search-react-select__multi-value {
  padding: 2px 0px;
  position: relative;
  width: 100%;
}

.react-select .hire-search-react-select__multi-value__label {
  font-size: 0.875rem;
  font-weight: 500;
}

.react-select .hire-search-react-select__multi-value__remove {
  position: absolute;
  right: 0px;
  top: 10px;
}

.react-select .hire-search-react-select__multi-value__remove:hover {
  background-color: transparent;
  /* color: #DE350B; */
}

.react-select .profile-react-select__indicator-separator,
.react-select .hire-react-select__indicator-separator,
.react-select .hire-search-react-select__indicator-separator {
  width: 0px;
}

.react-select .hire-search-react-select__clear-indicator,
.react-select .hire-search-react-select__dropdown-indicator {
  padding: 4px;
}

.social-media-link-edit input {
  background: var(--main-white);
  box-shadow: 0px 2px 10px 5px #75757510;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-family: var(--main-font);
  padding: 0px 15px;
}

.competence-dash input {
  background: var(--main-white);
  box-shadow: 0px 2px 10px 5px #75757510;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-family: var(--main-font);
  padding: 0px 15px;
}

.dashboard-form label {
  color: var(--main-black);
  font-family: var(--main-font);
  font-size: 16px;
  font-weight: 700;
}

.dash-lanuguage {
  display: flex;
  position: relative;
}

.dash-lanuguage .btn-delete {
  position: absolute;
  z-index: 1;
  right: -16px;
  top: 10px;
  color: red;
  font-size: 0.875rem;
  font-weight: 500;
  box-shadow: none !important;
  outline: none !important;
}

.dash-lanuguage .form-group {
  width: 48%;
}

.dash-lanuguage .dash {
  width: 4%;
  text-align: center;
  margin-top: 5px;
  font-weight: bold;
}

.language-add-btn-plus {
  width: 35px;
  height: 35px;
  background: var(--main-white);
  box-shadow: 0px 2px 10px 5px #75757510;
  font-size: 25px;
  border-radius: 100px;
  display: inline-block;
  text-align: center;
}

#Rectangle_317-2:hover {
  fill: rgb(241, 241, 241) !important;
}

.social-media-link-edit>div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.social-media-link-edit>div>img {
  margin-right: 15px;
  width: 35px;
  height: 35px;
}

.dashboard-subscribe-content h5 {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.competence-dash h6,
.skill-add-section h6 {
  font-family: var(--main-font);
  font-weight: 600 !important;
  font-size: 18px;
  margin-bottom: 15px;
}

.competence-dash>.grey-dot-div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.competence-dash>.grey-dot-div::before {
  content: url('../images/gre-dot.svg');
  margin-right: 10px;
}

.competence-add-btn {
  margin-left: 15px;
}

.experience-inner-box {
  border-radius: 20px;
  box-shadow: 0px 2px 10px 5px #75757510;
  padding: 30px 20px;
  position: relative;
}

.experience-inner-box .btn-delete {
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 8px;
  color: red;
  font-size: 1rem;
  font-weight: 500;
  box-shadow: none !important;
  outline: none !important;
}

.experience-inner-box .gry-input {
  background: var(--dark-gray);
  border-radius: 30px;
  border: none;
  color: var(--main-black);
  font-family: var(--main-font);
  font-weight: 500;
}

.exp-date-part .dash-date-picker {
  max-width: 168px;
  padding: 5px;
  font-size: 15px;
  font-family: var(--main-font);
  border: 1px solid var(--main-black);
  border-radius: 50px;
}

.exp-date-part {
  display: flex;
  align-items: center;
}

.exp-date-part span {
  line-height: 35px;
  font-family: var(--main-font);
  font-size: 15px;
  margin-right: 8px;
}

.en-course-btn {
  background: var(--main-white) !important;
  border: 1px solid var(--main-blue) !important;
  border-radius: 50px;
  color: var(--main-blue) !important;
  font-weight: 600;
  margin-left: 15px;
  padding: 8px 15px 10px 15px;
  outline: none !important;
}

.en-course-btn:hover {
  background: var(--light-gray) !important;
}

.en-course-btn.active {
  background: var(--main-blue) !important;
  border: 1px solid var(--main-blue) !important;
  border-radius: 50px;
  color: var(--main-white) !important;
  padding: 8px 15px 10px 15px;
  margin-left: 15px;
  font-weight: 700;
}

.au {
  margin-left: 20px;
}

.poste .poste-input {
  padding: 5px 15px;
  font-size: 16px;
  font-family: var(--main-font);
  border: 1px solid var(--main-black);
  border-radius: 50px;
  max-width: 250px;
}

.poste .text-area-exp {
  width: 100% !important;
  padding: 10px 15px;
  font-size: 16px;
  font-family: var(--main-font);
  border: 1px solid var(--main-black);
  border-radius: 20px;
}

.round-inputs-box input {
  border: 1px solid var(--main-black);
  border-radius: 50px;
  display: inline-block;
  font-size: 16px;
  font-family: var(--main-font);
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 4px 12px;
  /* max-width: 176px; */
  width: min-content;
  text-align: center;
}

.add-experience {
  border: 1px dashed var(--main-black) !important;
  padding: 8px 8px !important;
}

.project-box.add-project.w-100 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
}

.modal-backdrop.show {
  opacity: 0.1 !important;
}

#portfolioModal .modal-content {
  border-radius: 20px;
  background: var(--main-white);
  box-shadow: 0px 2px 10px 5px #75757510;
  border: none;
  padding: 15px;
}

#portfolioModal .gry-input {
  background: var(--dark-gray);
  border-radius: 30px;
  border: none;
  color: var(--main-black);
  font-family: var(--main-font);
  font-weight: 500;
  margin: 0 auto;
  max-width: 360px;
}

#portfolioModal .close {
  position: absolute;
  top: 0px;
  right: 16px;
  background-color: transparent;
  color: var(--main-black) !important;
  font-size: 1.75rem;
  font-weight: 600;
  margin: 0px;
  padding: 5px 5px;
  outline: none !important;
  box-shadow: none !important;
}

#portfolioModal .modal-footer .btn {
  border-radius: 50px;
  font-size: 1.125rem;
  font-weight: 500;
}

#portfolioModal .pf-img {
  display: block;
  padding: 16px;
  margin: 0 auto;
  /* width: 80%; */
  max-width: 320px;
}

.drag-and-drop-img {
  border: 1px dashed;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  max-width: 350px;
  margin: 0px auto;
}

/* message-page */
.mesage-box {
  justify-content: unset;
  align-items: unset;
}

.search-chat-box .gry-input {
  background: var(--dark-gray);
  border-radius: 30px;
  border: none;
  color: var(--main-black);
  font-family: var(--main-font);
  font-weight: 400;
  font-size: 12px;
  padding-left: 35px;
}

.search-chat-box::before {
  content: url('../images/gry-search-icon.svg');
  position: absolute;
  top: 7px;
  left: 30px;
}

.chat-p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.chat-person-list .nav-link.active {
  color: var(--main-white) !important;
  font-family: var(--main-font);
  margin-left: 10px;
  font-weight: 500;
  background-color: var(--main-blue) !important;
  border-radius: 10px !important;
}

.chat-person-list .nav-link {
  color: #495969;
  font-family: var(--main-font);
  margin-left: 10px;
  font-weight: 500;
}

.chat-p-name {
  font-size: 16px;
  margin-left: 10px;
}

/* chat */
.msg_history {
  height: 560px !important;
  overflow-y: auto;
  padding: 20px 10px;
}

.incoming_msg_img img {
  width: 35px;
}

.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.incoming_msg_img {
  display: inline-block;
}

.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}

.received_withd_msg p {
  background: var(--dark-gray);
  border-radius: 7px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
  font-family: var(--main-font);
}

.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 5px;
}

.received_withd_msg {
  width: 57%;
}

.mesgs {
  float: left;
  position: relative;
  width: 100%;
}

.mesgs .open_conversation_msg {
  color: #747474;
  font-size: 1.5rem;
  top: 50%;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
}

.sent_msg p {
  background: var(--main-blue);
  border-radius: 7px;
  font-size: 14px;
  margin: 0;
  color: var(--main-white);
  padding: 5px 10px 5px 12px;
  width: 100%;
  margin-bottom: 8px;
  font-family: var(--main-font);
}

.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}

.sent_msg {
  float: right;
  width: 46%;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}

.msg_send_btn {
  background-color: #efefef !important;
  border-radius: 50%;
  color: var(--main-white);
  cursor: pointer;
  height: 33px;
  position: absolute;
  /* right: 55px; */
  right: 13px;
  top: 21px;
  width: 33px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .msg_send_btn-2 {
  background-color: #efefef !important;
  border-radius: 50%;
  color: var(--main-white);
  cursor: pointer;
  height: 33px;
  position: absolute;
  right: 13px;
  top: 21px;
  width: 33px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.msg_send_btn:hover svg path,
.msg_send_btn-2:hover svg path {
  stroke: var(--main-blue);
  transition: 0.2s all ease-in-out;
}

.messaging {
  padding: 0 0 50px 0;
}

.msg_history {
  height: 516px;
  overflow-y: auto;
}

.input_msg_write input {
  background: var(--main-white);
  box-shadow: 0px 2px 10px 5px #75757510;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-family: var(--main-font);
  padding: 5px 20px;
  width: 100%;
  min-height: 45px;
  font-weight: 500;
  padding-right: 100px;
}

.input_msg_write input:focus {
  border: none;
  outline: none;
}

.type_msg {
  border-top: 1px solid var(--dark-gray);
  position: relative;
  margin-top: 15px;
}

.input_msg_write {
  margin-top: 15px;
}

.day-details {
  font-size: 12px;
  font-family: var(--main-font);
  font-weight: 500;
  margin: 25px 0px;
  border-bottom: 1px solid rgb(230, 230, 230);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.day-details span {
  position: absolute;
  background: var(--main-white);
  padding: 5px 20px;
}

/* setting1 */
.dashbord-setting1 {
  align-items: flex-start;
}

.dash-head h2 {
  font-weight: bold;
}

.dash-head h5,
.payment-p h5 {
  font-size: 20px !important;
  font-weight: bold;
  font-family: var(--main-font);
  line-height: 2;
}

.fa-eye-slash:before,
.fa-eye:before {
  /* content: "\f070"; */
  position: absolute;
  top: 44px;
  right: 28px;
  color: #D8D8D8;
}

#userProfileForm .form-control.is-invalid {
  background-position: right 0.375rem center;
  background-size: 1rem 1rem;
}

a,
a:hover {
  color: #333
}

.dsb-fm label {
  color: var(--main-black);
  font-family: var(--main-font);
  font-size: 16px;
  font-weight: 500;
}

.payment-p p {
  font-size: 18px;
  font-family: var(--main-font);
  color: #969696;
}

/* setting 2 */
.payment-card svg {
  margin-left: -30px;
}

/* responsive css */
@media (max-width: 1920px) {
  #sidebar-wrapper {
    min-height: calc(100vh - 87px);
    margin-left: 0px;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
    padding: 20px;
  }

  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }

  #sidebar-wrapper .list-group {
    width: 270px;
  }

  .dashboard-h2 {
    font-size: 25px;
  }

  .generate-box h3 {
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }

  .balance-btn {
    margin-right: 15px;
    background: var(--main-white);
    padding: 10px 13px;
    border-radius: 10px;
    margin-left: auto;
    font-size: 14px;
  }

  .logout-btn.list-group-item {
    display: inline-block;
    margin-top: 350px !important;
    margin-top: 100px !important;
  }
}

@media only screen and (max-width: 1440px) {
  .skill-section {
    margin-top: 200px;
  }

  .skill-section .card-title {
    text-align: center;
  }

  .arrow-hero::after {
    content: url('../images/icon-down.svg');
    position: relative;
    top: 150px;
  }

  .hero-section {
    height: 75vh;
  }

  h4.sub-head {
    margin-top: 200px;
  }

  .arrow-img {
    margin-top: 0px !important;
    position: absolute;
  }

  .contact-us-form label {
    margin-bottom: 4px;
  }

  .btn-contact-yellow {
    padding: 8px 64px !important;
  }

  .contact-us-form {
    padding: 12px 24px;
  }

  .contact-us-form .form-group {
    margin-bottom: 10px;
  }

  body.c-form-page {
    background-position-y: -15vh !important;
  }

  .three-tab-section li {
    width: 80%;
  }

  .contact-us-form textarea {
    padding: 8px 12px;
  }

  #sidebar-wrapper {
    margin-left: -360px !important;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0px !important;
    transition: margin 0.25s ease-out;
  }

  .list-group-item {
    padding: 6px 15px !important;
  }

  #sidebar-wrapper {
    width: 290px;
  }

  #sidebar-wrapper .list-group {
    width: 200px;
  }

  .list-group-item svg {
    width: 22px;
  }

  .msg_history {
    height: 440px !important;
    overflow-y: auto;
    padding: 20px 10px;
  }
}

@media only screen and (max-width: 1366px) {
  .contact-us {
    background-position: 75%;
  }

  .serach-section {
    margin-top: 44px;
  }

  .home-img-part {
    margin-top: 60px;
    max-width: 45%;
  }

  .bots .skill-m-title {
    margin-top: 10px;
  }

  .home-hero-section .hero-title {
    margin-top: 175px;
  }

  .skill-section {
    margin-top: 300px;
  }

  .form-section input {
    border-radius: 50px;
    padding: 24px;
    border: 1px solid #132057;
    font-family: var(--main-font);
  }

  .btn-next {
    padding: 10px 20px !important;
  }

  body.c-form-page {
    background-position-y: -20vh !important;
  }

  .msg_history {
    height: 320px !important;
    overflow-y: auto;
    padding: 20px 10px;
  }

  .inner-box-project.add-project-btn {
    text-align: center;
  }
}

@media only screen and (max-width: 1024px) {
  .skill-section {
    margin-top: 200px;
  }

  .rate {
    padding: 5px 10px;
  }

  .sign-up-btn {
    display: inline-block !important;
    margin-top: 15px;
    margin-left: 5px;
  }

  body {
    background: none;
  }

  .header-nav-bg {
    background: url('../images/Background.svg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
  }

  .home-img-part {
    display: none;
  }

  .home-hero-section {
    height: 50vh;
  }

  .home-hero-section .hero-title {
    margin-top: 100px;
  }

  .container.home-hero-section {
    justify-content: center;
  }

  .news-box-part .card {
    margin-bottom: 2rem !important;
  }

  .arrow-img {
    margin-top: -120px !important;
    position: absolute;
  }

  .top-proxy-container .proxy-box {
    flex-wrap: wrap;
  }

  .card-columns.grid-result {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .home-hero-section {
    height: 75vh;
  }

  .hero-section.contact-us-section {
    flex-wrap: wrap;
    height: auto;
  }

  .three-tab-section {
    width: 100%;
    margin-right: unset;
  }

  .hello-what-we-do {
    width: 100%;
  }

  .three-tab-section ul {
    width: 50%;
    margin: auto;
  }

  .three-tab-section li {
    width: 100%;
  }

  .contact-us-form {
    width: 50vw;
  }

  .price-by-day {
    margin-bottom: 15px;
  }

  .exp-date-part {
    display: flex;
    align-items: center;
    margin-top: 15px;
    flex-wrap: wrap;
  }

  .d-flex.au {
    margin-left: unset;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .card-columns {
    column-count: 2 !important;
  }

  .skill-section,
  .portfolio-section,
  .contact-us {
    margin-top: 80px;
  }

  .contact-us {
    background-size: cover;
    background-position: left;
  }

  .cbox {
    justify-content: center !important;
    text-align: center !important;
    margin-bottom: 60px;
  }

  .price-font {
    color: var(--main-white);
    text-align: center;
    margin-bottom: 10px;
  }

  .contact-us-btn {
    background: var(--main-white);
    color: var(--main-blue);
  }

  .contact-us-btn:hover {
    background: #ebe9e9;
  }

  .contact-us-footer-btn {
    padding: 10px 16px;
  }

  h4.sub-head {
    margin-top: 100px;
  }

  .logo-grey-box-container,
  .bots-part,
  .top-proxy-container {
    grid-template-columns: 1fr;
  }

  .profile-proposer-btn {
    margin: 0 auto;
  }

  .page-content-dashboard {
    height: auto !important;
    margin-bottom: 50px;
  }

  .col-lg-4.border-right {
    border-right: none !important;
    border-bottom: 1px solid rgb(226, 226, 226);
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .hero-section {
    height: 50vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 125px;
  }

  .footer-inner .col-md-3 {
    margin-bottom: 25px;
  }

  footer .row>*+* {
    display: flex;
    justify-content: left;
  }

  .contact-us-footer-btn {
    margin-left: -10px;
    margin-bottom: 15px;
  }

  .card-columns {
    column-count: 1 !important;
  }

  .hide-br {
    display: none;
  }

  h2.hero-t.home-hero-txt.text-left {
    font-size: 16px;
  }

  .home-hero-section .hero-title {
    margin-top: 150px;
  }

  .freelancer-found {
    margin-bottom: 15px;
  }

  .mt-50 {
    margin-top: 15px;
  }

  .proxy-img img,
  .proxy-img {
    width: 100%;
    max-width: 100%;
  }

  .card-columns.grid-result {
    display: grid;
    grid-template-columns: 1fr;
  }

  .three-tab-section {
    margin-right: unset;
  }

  .hero-section.contact-us-section {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 580px) {
  .hero-section {
    height: 80vh;
    margin-top: 120px;
    padding: 15px;
  }

  .skill-section,
  .portfolio-section,
  .contact-us {
    margin-top: 50px;
  }

  .serach-section {
    padding: 4px 16px 4px 4px;
  }

  .search-btn {
    background: var(--main-blue);
    border-radius: 50%;
    height: 36px;
    width: 36px;
  }

  .search-btn .fas {
    font-size: 1rem;
  }

  .btn-next {
    font-size: 1rem !important;
    padding: 10px 8px !important;
  }

  .arrow-hero::after {
    content: url('../images/icon-down.svg');
    position: relative;
    top: 175px;
  }

  .home-hero-section .hero-title {
    margin-top: 104px;
  }

  .sub-text {
    color: #a3a3a3;
    font-family: var(--main-font);
  }

  h4.sub-head {
    margin-top: 50px;
  }

  .proxy-box {
    flex-wrap: wrap;
  }

  .proxy-img img,
  .proxy-img {
    width: 100%;
    max-width: 100%;
  }

  .proxy-buttons {
    padding: 8px 10px;
    background: var(--main-white);
    box-shadow: 0px 3px 15px 7px #00000010;
    border-radius: 74px;
    width: 45%;
    justify-content: space-between;
    margin: 8px;
    display: inline-block;
  }

  .proxy-buttons svg {
    width: 10px;
  }

  .freelancer-found {
    font-size: 1.25rem;
  }

  .freelancer-count {
    font-size: 2.5rem;
  }

  .mt150 {
    margin-top: 100px;
  }

  .form-section input {
    padding: 20px;
  }

  .form-check-label {
    text-align: left;
  }

  .blog-inner-title {
    margin-top: 50px;
  }

  .q-section h5::after {
    transform: scale(0.5);
    right: -50px;
  }

  .q-section h5::before {
    transform: scale(0.5);
    left: -45px;
    top: -15px;
  }

  .q-section h5 {
    font-size: 20px;
    width: 100%;
  }

  .dropdown-menu.grey-menu.show {
    display: flex;
    flex-wrap: wrap;
  }

  .three-tab-section ul {
    width: 100%;
    margin: auto;
  }

  .contact-us-section .tab-content {
    width: 100%;
  }

  .contact-us-form {
    width: 100%;
  }

  .three-tab-section li::after {
    right: 5px;
    transform: scale(0.8);
    top: 10px;
  }

  .three-tab-section .nav-link {
    line-height: 20px;
  }

  .left-skill h6,
  .right-skill h6 {
    margin-bottom: 20px;
  }

  .left-skill ul li,
  .right-skill ul li {
    margin: unset;
    margin-bottom: 10px !important;
    width: 100% !important;
  }

  .project-title h6 {
    font-size: 14px;
  }

  .page-content-dashboard {
    background-color: var(--main-white);
    margin-top: 10px;
    border-radius: 15px;
    padding: 20px 10px;
    height: 65vh;
    justify-content: center;
    align-items: center;
  }

  .logout-btn.list-group-item {
    display: inline-block;
    margin-top: 100px !important;
  }

  .dashboard-subscribe-content h5 {
    font-size: 16px;
    margin-top: 15px;
  }

  .dash-head h2 {
    font-size: 22px !important;
    font-weight: bold;
  }

  .exp-date-part .dash-date-picker {
    min-width: 100px;
  }

  .project-box.add-project.w-100 {
    min-height: 170px;
  }

  .inner-box-project.add-project-btn svg {
    width: 80%;
  }

  #wrapper {
    min-height: calc(100vh - 76px);
  }

  #sidebar-wrapper {
    background: var(--dark-gray) !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: scroll;
    z-index: 10;
  }

  #sidebar-wrapper .closebtn {
    display: block !important;
  }

  #sidebar-wrapper .list-group {
    width: 160px;
  }

  .list-group-item {
    padding: 2px 10px !important;
  }

  .en-course-btn {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }

  .payment-card svg {
    width: 140%;
  }

  .received_withd_msg {
    width: 90%;
  }

  .sent_msg {
    float: right;
    width: 80%;
  }
}

@media only screen and (max-width: 360px) {
  .navbar-brand img {
    max-width: 160px;
  }

  .proxy-buttons {
    width: 42%;
  }

  .box-icon .skill-m-title {
    margin-top: 5px;
  }

  .contact-us-btn.more-btn {
    padding: 5px 10px !important;
    font-size: 0.75rem;
  }
}

/* scroll bar */
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-blue);
  border-radius: 8px;
}

/* width */
.table-responsive::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.table-responsive::-webkit-scrollbar-thumb {
  background: #fc4a4a;
  border-radius: 8px;
}

/* loader */
#loader {
  /* background: #2a2b36 url('../images/load.svg') center no-repeat 100px; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
}

.loader-container {
  align-items: center;
  background: var(--main-blue) url('../images/Logo-SneackersnCo_Large.png') no-repeat center/150px;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.loading-screen {
  height: 175px;
  width: 175px;
  border-radius: 50%;
  border: 1px solid var(--main-white);
  border-right: none;
  border-bottom: none;
  animation-name: spin, fade;
  animation-duration: 3s, 3s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1), linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1170deg);
  }
}

@keyframes fade {

  0%,
  100%,
  70% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.rc-slider-handle,
.rc-slider-handle:hover {
  border: solid 2px var(--main-blue) !important;
}

.rc-slider-handle:active {
  border-color: var(--main-blue) !important;
  box-shadow: 0 0 5px var(--main-blue-hover) !important;
}

.rc-slider-tooltip-placement-top {
  width: 80px;
}

.rc-slider-tooltip-arrow {
  border-top-color: var(--main-blue) !important;
}

.rc-slider-tooltip-inner {
  background-color: var(--main-blue) !important;
}

/* sidebar */
/* The side navigation menu */
.sidenav {
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--main-white);
  overflow-x: hidden;
  padding-top: 2px;
  transition: 0.2s;
}

.filter-title,
.filter-badges,
.checkbox-section {
  padding: 4px 8px;
}

.checkbox-div {
  padding-left: 20px;
}

.checkbox-div .form-check-input {
  margin-top: 0.5rem;
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 20px;
  text-decoration: none;
  font-size: 16px;
  color: var(--main-blue);
  display: block;
  transition: 0.1s;
}

.sidenav a:hover {
  color: #919191;
}

#sidebar-wrapper .closebtn,
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 16px;
  font-size: 2.5rem;
}

#sidebar-wrapper .closebtn {
  display: none;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.2s;
  padding: 20px;
}

#sidebar-wrapper::-webkit-scrollbar-thumb,
.sidenav::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 8px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-width: 1440px) {
  .sidenav {
    width: 0px;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

/* developer css */
.Toastify__toast {
  font-family: var(--main-font);
}

.not-allowed {
  cursor: not-allowed;
  pointer-events: none !important;
  opacity: 0.6;
}

.pointer {
  cursor: pointer !important;
}

/* Upload image modal css */

.modal-backdrop.show {
  opacity: 0.65 !important;
}

#ImgUploadModal,
#ImgUploadModal .modal-content {
  border: 0px;
  border-radius: 10px;
}

#ImgUploadModal .modal-content {
  border: 0px;
  border-radius: 10px;
}

#ImgUploadModal .modal-header {
  background-color: #000000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: var(--main-white);
  padding: 8px 16px;
}

#ImgUploadModal .modal-header .title {
  font-size: 1rem;
  font-weight: 400;
}

#ImgUploadModal .modal-header .close {
  /* background-color: #000000; */
  color: var(--main-white);
  font-size: 24px;
  font-weight: 400;
  text-shadow: none;
}

#ImgUploadModal .modal-footer {
  background-color: #000000;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#ImgUploadModal .modal-footer .btn-white {
  background-color: var(--main-white);
  border-radius: 50px;
  box-shadow: none !important;
  color: #000;
  font-weight: 500;
  padding: 5px 16px;
  outline: none !important;
}

/* Chat messages styling */

.chat-list {
  height: 350px !important;
  padding: 20px 10px;
  overflow-y: auto;
}

.chat-list .rce-container-citem {
  border: 0px !important;
}

.chat-list .rce-citem {
  margin: 4px 0px;
  height: 64px;
}

.chat-list .rce-citem.active {
  background-color: var(--main-blue) !important;
  border: 0px !important;
  border-radius: 10px !important;
  color: var(--main-white);
}

.chat-list .rce-citem:hover {
  background-color: var(--main-blue-hover) !important;
  border: 0px !important;
  border-radius: 10px !important;
  color: var(--main-white);
}

.chat-list .rce-citem:hover .rce-citem-body--top-time,
.chat-list .rce-citem:hover .rce-citem-body--top-title,
.chat-list .rce-citem:hover .rce-citem-body--bottom-title,
.chat-list .rce-citem.active .rce-citem-body--top-time,
.chat-list .rce-citem.active .rce-citem-body--top-title,
.chat-list .rce-citem.active .rce-citem-body--bottom-title {
  color: var(--main-white);
}

.chat-list .rce-citem .rce-citem-status {
  width: 16px;
  height: 16px;
  bottom: 12px;
  right: 12px;
}

.chat-list .rce-citem-body--top-title {
  font-size: 1rem;
  font-weight: 500;
}

.chat-list .rce-citem-body--bottom-title {
  font-size: 0.875rem;
}

.message-list .rce-mbox-body .rce-mbox-text {
  font-size: 1rem;
  /* font-weight: 500; */
}

.message-list .rce-mbox {
  max-width: 425px;
}

.message-list .rce-mbox.rce-mbox-right {
  background-color: var(--main-blue) !important;
  box-shadow: 1px 1px 1px 1px #2e49be80;
  color: var(--main-white);
}

.message-list .rce-mbox.rce-mbox-right .rce-mbox-right-notch {
  fill: var(--main-blue);
}

.message-list .rce-mbox.rce-mbox-right .rce-mbox-time {
  color: var(--main-white);
}